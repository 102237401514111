<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">
              {{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[69]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-12">
            <div class="m_sidebar">
              <div class="m_sidebar_title">
                {{$store.state.language.lang[3]}}
              </div>
              <div class="m_sidebar_link">
                <router-link to="/news">
                  {{$store.state.language.lang[69]}}</router-link>
              </div>

              <div class="m_sidebar_link">
                <router-link to="/press_secretary">
                  {{$store.state.language.lang[71]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/press_releases">
                  {{$store.state.language.lang[72]}}</router-link>
              </div>
              <!-- <div class="m_sidebar_link">
                <router-link to="/tenders">
                  {{$store.state.language.lang[74]}}</router-link>
              </div> -->
              <div class="m_sidebar_link">
                <router-link to="/media">
                  {{$store.state.language.lang[70]}}</router-link>
              </div>
            </div>

          </div>
          <div class="col-xl-9 col-12">
            <div class="m_body_title">
              <h2>  {{$store.state.language.lang[69]}}</h2>
            </div>
            <MediaSideNews :name="$store.state.language.lang[69]"></MediaSideNews>
<!--            <div class="row">-->
<!--              <div class="col-xl-6">-->
<!--                <label class="data_select">  {{$store.state.language.lang[4]}}</label>-->
<!--                <select class="form-control" @change="filter()" v-model="year">-->
<!--                  <option value="2022">2022</option>-->
<!--                  <option value="2023">2023</option>-->
<!--                  <option value="2024">2024</option>-->
<!--                  <option value="2025">2025</option>-->
<!--                  <option value="2026">2026</option>-->
<!--                  <option value="2027">2027</option>-->
<!--                  <option value="2028">2028</option>-->
<!--                  <option value="2029">2029</option>-->
<!--                  <option value="2030">2030</option>-->
<!--                  <option value="2031">2031</option>-->
<!--                  <option value="2032">2032</option>-->
<!--                  <option value="2033">2033</option>-->
<!--                  <option value="2034">2034</option>-->
<!--                  <option value="2035">2035</option>-->
<!--                  <option value="2036">2036</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="col-xl-6">-->
<!--                <label class="data_select">{{$store.state.language.lang[5]}}</label>-->
<!--                <select class="form-control" @change="filter()" v-model="month">-->
<!--                  <option value="1">Dekabr</option>-->
<!--                  <option value="2">Yanvar</option>-->
<!--                  <option value="3">Fevral</option>-->
<!--                  <option value="4">Mart</option>-->
<!--                  <option value="5">Aprel</option>-->
<!--                  <option value="6">May</option>-->
<!--                  <option value="7">Iyun</option>-->
<!--                  <option value="8">Iyul</option>-->
<!--                  <option value="9">Avgust</option>-->
<!--                  <option value="10">Sentabr</option>-->
<!--                  <option value="11">Oktabr</option>-->
<!--                  <option value="12">Noyabr</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </div>-->
            <div class="news_card_child">
              <div class="row">
                <div class="col-xl-4 col-sm-6 col-12" v-for="(item,index) in $store.state.newsM.results" :key="index">
                  <router-link :to="'/news/'+item.id+'?lang='+languages">
                    <div class="card_child_content">
                      <div class="news_cards">
                        <img :src="item.image" alt="">
                      </div>

                      <div class="news_card_child1 p-2">
                        <div>

                          <div class="card_cild_title">
                            {{item.title}}
                          </div>
                          <div class="card_date_child news_date">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M7.99996 4.77994V7.99911L10.2225 9.03244M14.665 7.99911C14.665 11.6801 11.6809 14.6641 7.99996 14.6641C4.31898 14.6641 1.33496 11.6801 1.33496 7.99911C1.33496 4.31813 4.31898 1.33411 7.99996 1.33411C11.6809 1.33411 14.665 4.31813 14.665 7.99911Z"
                                  stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            {{new Date( item.created_on).toLocaleDateString()}}
                          </div>
                        </div>

                        <div class="arrow_icons">
                          <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.15061 0.885582C7.95535 0.69032 7.63877 0.69032 7.4435 0.885582C7.24824 1.08084 7.24824 1.39743 7.4435 1.59269L8.15061 0.885582ZM12.5579 5.99997L12.9114 6.35352C13.0052 6.25975 13.0579 6.13258 13.0579 5.99997C13.0579 5.86736 13.0052 5.74018 12.9114 5.64642L12.5579 5.99997ZM7.4435 10.4072C7.24824 10.6025 7.24824 10.9191 7.4435 11.1144C7.63877 11.3096 7.95535 11.3096 8.15061 11.1144L7.4435 10.4072ZM1.46289 5.49997C1.18675 5.49997 0.962891 5.72383 0.962891 5.99997C0.962891 6.27611 1.18675 6.49997 1.46289 6.49997V5.49997ZM12.5437 6.49997C12.8199 6.49997 13.0437 6.27611 13.0437 5.99997C13.0437 5.72383 12.8199 5.49997 12.5437 5.49997V6.49997ZM7.4435 1.59269L12.2043 6.35352L12.9114 5.64642L8.15061 0.885582L7.4435 1.59269ZM12.2043 5.64642L7.4435 10.4072L8.15061 11.1144L12.9114 6.35352L12.2043 5.64642ZM1.46289 6.49997H12.5437V5.49997H1.46289V6.49997Z"
                                fill="white"/>
                          </svg>
                        </div>
                      </div>

                      <div class="card_vectors">
                        <img src="../../assets/img/cr_vec.png" alt="">
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="pattern_news">
                <div class="patern_main_img">
                  <div class="contens_news">
                    <div class="content_left">
                      {{$store.state.language.lang[77]}}
                    </div>
                    <div class="content_right">
                      <div class="cont_text">
                        {{$store.state.language.lang[45]}}
                      </div>
                      <div class="messengers">
                        <a href="#">
                          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30 9.97927C30 7.86528 28.1347 6 26.0207 6H9.97927C7.86528 6 6 7.86528 6 9.97927V26.0207C6 28.1347 7.86528 30 9.97927 30H18.0622V20.9223H15.0777V16.943H18.0622V15.3264C18.0622 12.5907 20.0518 10.228 22.5389 10.228H25.772V14.2072H22.5389C22.1658 14.2072 21.7927 14.5803 21.7927 15.3264V16.943H25.772V20.9223H21.7927V30H26.0207C28.1347 30 30 28.1347 30 26.0207V9.97927Z" fill="white"/>
                            <rect x="1" y="1" width="34" height="34" rx="7" stroke="white" stroke-width="2"/>
                          </svg>
                        </a>
                        <a href="#">

                          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="34" height="34" rx="7" stroke="white" stroke-width="2"/>
                            <path d="M30 11.1923C29.1429 11.5385 28.1633 11.7692 27.1837 11.8846C28.1633 11.3077 29.0204 10.3846 29.3878 9.34616C28.4082 9.92308 27.4286 10.2692 26.2041 10.5C25.3469 9.57693 24 9 22.6531 9C19.9592 9 17.7551 11.0769 17.7551 13.6154C17.7551 13.9615 17.7551 14.3077 17.8776 14.6538C13.8367 14.4231 10.1633 12.5769 7.71428 9.80769C7.34693 10.5 7.10203 11.3077 7.10203 12.1154C7.10203 13.7308 7.95918 15.1154 9.30612 15.9231C8.44898 15.9231 7.71428 15.6923 7.10203 15.3462C7.10203 17.5385 8.81632 19.5 11.0204 19.8461C10.6531 19.9615 10.1633 19.9615 9.67347 19.9615C9.30612 19.9615 9.06122 19.9615 8.69388 19.8461C9.30612 21.6923 11.1429 23.0769 13.3469 23.0769C11.6327 24.3461 9.55102 25.0384 7.22449 25.0384C6.85714 25.0384 6.4898 25.0385 6 24.9231C8.20408 26.1923 10.7755 27 13.5918 27C22.6531 27 27.551 19.9615 27.551 13.8461V13.2692C28.5306 12.9231 29.3878 12.1154 30 11.1923Z" fill="white"/>
                          </svg>

                        </a>
                        <a href="#">

                          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="34" height="34" rx="7" stroke="white" stroke-width="2"/>
                            <path d="M6.42084 17.5798L11.9386 19.6436L14.0895 26.5854C14.183 27.0545 14.7442 27.1483 15.1182 26.8668L18.2044 24.334C18.485 24.0526 18.9526 24.0526 19.3267 24.334L24.8443 28.3678C25.2184 28.6492 25.7796 28.4616 25.8731 27.9925L29.988 8.29282C30.0815 7.82378 29.6139 7.35474 29.1463 7.54236L6.42084 16.3603C5.85972 16.5479 5.85972 17.3922 6.42084 17.5798ZM13.809 18.6117L24.6573 11.9513C24.8443 11.8575 25.0314 12.139 24.8443 12.2328L15.9599 20.5817C15.6794 20.8631 15.3988 21.2384 15.3988 21.7074L15.1182 23.9588C15.1182 24.2402 14.6506 24.334 14.5571 23.9588L13.4349 19.8312C13.1543 19.3622 13.3414 18.7993 13.809 18.6117Z" fill="white"/>
                          </svg>

                        </a>
                        <a href="#">

                          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="34" height="34" rx="7" stroke="white" stroke-width="2"/>
                            <path d="M6.42084 17.5798L11.9386 19.6436L14.0895 26.5854C14.183 27.0545 14.7442 27.1483 15.1182 26.8668L18.2044 24.334C18.485 24.0526 18.9526 24.0526 19.3267 24.334L24.8443 28.3678C25.2184 28.6492 25.7796 28.4616 25.8731 27.9925L29.988 8.29282C30.0815 7.82378 29.6139 7.35474 29.1463 7.54236L6.42084 16.3603C5.85972 16.5479 5.85972 17.3922 6.42084 17.5798ZM13.809 18.6117L24.6573 11.9513C24.8443 11.8575 25.0314 12.139 24.8443 12.2328L15.9599 20.5817C15.6794 20.8631 15.3988 21.2384 15.3988 21.7074L15.1182 23.9588C15.1182 24.2402 14.6506 24.334 14.5571 23.9588L13.4349 19.8312C13.1543 19.3622 13.3414 18.7993 13.809 18.6117Z" fill="white"/>
                          </svg>

                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pagenation">
                <paginate
                    :page-count="$store.state.newsM.total_pages"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="clickCallback"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                >
                </paginate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";
import MediaSideNews from "../MediaSideNews";
export default {
  components: {
    Footer,
    Navbar,
    MediaSideNews
  },
  data() {
    return {
      year:'2022',
      isActive_m: false,
      selected:'',
      selected1:'Dekabr',
      month:'',
      options: [
        { value: null, text: 'Please select some item' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Default Selected Option' },
        { value: 'c', text: 'This is another option' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ],
      options1: [
        { value: null, text: 'Please select some item' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Default Selected Option' },
        { value: 'c', text: 'This is another option' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ],
      languages:localStorage.getItem('lang')
    }
  },
  mounted() {
    this.$store.dispatch('newsM',1)
    this.languages=localStorage.getItem('lang')
  },
  methods: {
    clickCallback (pageNum){

      this.$store.dispatch('newsM',pageNum)
    },
    filter(){

      this.$store.dispatch('newsMfiltr',{
        "year":this.year,
        "month":this.month
      })
    }
  }
}
</script>
